
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IResourceSearchFacet } from '@/types/Search';

@Component({
	name: 'FacetChipGroup'
})
export default class FacetChipGroup extends Vue {

	/* Props
    ============================================*/

	@Prop({type: Object as () => IResourceSearchFacet, required: true})
	readonly facet: IResourceSearchFacet;

	/* Computed
    ============================================*/

	get selectedValues() {
		return this.facet.values.filter(v => v.isSelected);
	}
}
