



















import Vue from 'vue';
import { Get, Sync } from 'vuex-pathify';
import { Component } from 'vue-property-decorator';
import { IResourceSearchFacet} from '@/types/Search';
import FacetChipGroup from './FacetChipGroup.vue';
import UiButton from '@/components/global/UiButton.vue';

@Component({
	name: 'AppliedSearchFacets',
	components: { FacetChipGroup, UiButton }
})
export default class AppliedSearchFacets extends Vue {

	/* Computed
    ============================================*/

    @Sync('resource/query@selectedFacets')
	selectedFacets: IResourceSearchFacet[];

	@Get('resource/isSingleResult')
	readonly isSingleResult: boolean;

	/* Methods
    ============================================*/

	deselectFacetValue(facetValue) {
    	this.selectedFacets.forEach(
    		f => f.values.forEach(v => {
				if (v.label !== facetValue.label) return;
				v.isSelected = false;
			})
    	);
	}

	async deselectAllFacetValues() {
		this.selectedFacets.forEach(f => f.values.forEach(v => v.isSelected = false));
	}

}
