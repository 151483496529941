















	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';

	@Component({
		name: 'SearchFacetWrapper',
	})
	export default class SearchFacetWrapper extends Vue {

		/* Props
        ============================================*/
		@Prop({type: String, required: true})
		readonly label: string;

		@Prop({type: Boolean, required: false, default: true})
		readonly collapsable: boolean;

		@Prop({ type: Boolean, default: false })
		readonly initState: boolean = false;

		/* Data
        ============================================*/
		isCollapsed: boolean = this.initState;

		@Watch('initState')
		onInitStateChange(cur, prev) {
			this.isCollapsed = cur;
		}
	}

