





















import Vue from 'vue';
import { Sync, Get } from 'vuex-pathify';
import { Component } from 'vue-property-decorator';
import { IResourceSearchFacet } from '@/types/Search';
import { jsonCopy } from '@/utils/functions';

@Component({
	name: 'ResourceFacets'
})
export default class ResourceFacets extends Vue {

	/* Computed
	============================================*/

    @Sync('resource/query@selectedFacets')
	facets: IResourceSearchFacet[];

	@Get('resource/facetsOfSingleTypeSelected')
	facetsOfSingleTypeSelected: Boolean;

	@Get('resource/facetsOfResourceTypesSelected')
	facetsOfResourceTypesSelected: String[];

    get availableFacets() {
    	return this.facets.filter(f => f.values.length > 0);
    }

	/* Data
	============================================*/
	activeSearchFacet: IResourceSearchFacet = null;

	/* Methods
	============================================*/
	isExpanded(f) {
		if (!this.facetsOfSingleTypeSelected || f.name === 'resourceType') return false;
		const sameResourceType = f.values.some(
			v => v.resourceType === this.facetsOfResourceTypesSelected[0]
		);
		return !sameResourceType;
	}

	closeSearchFacetModal() {
		this.activeSearchFacet = null;
	}

	deselectAllFacetValues() {
		this.activeSearchFacet.values.forEach(v => v.isSelected = false);
	}

	expandSearchFacet(facet) {
		this.activeSearchFacet = jsonCopy(facet);
	}

	applySearchFacetValues() {
		this.facets = this.facets.slice().map(
			f => this.activeSearchFacet.name === f.name ? this.activeSearchFacet : f
		);
		this.closeSearchFacetModal();
	}

}

