



















import PermissionResolver from '@/services/Permissions/PermissionResolver';
import { Get, Sync } from 'vuex-pathify';
import { Component, Prop } from 'vue-property-decorator';
import { jsonCopy } from '@/utils/functions';
import { IEaasiSearchQuery, IEaasiSearchResponse } from '@/types/Search';
import { IEaasiUser } from 'eaasi-admin';
import AdminScreen from '@/components/admin/AdminScreen.vue';
import Pagination from '@/components/global/Pagination.vue';
import User from '@/models/admin/User';
import UserList from './UserList.vue';
import UserModal from './UserModal.vue';

@Component({
	name: 'UserManagement',
	components: {
		UserList,
		UserModal,
		Pagination,
	}
})
export default class UserManagement extends AdminScreen {

	/* Props
	============================================*/

	@Prop({type: Boolean, required: false})
	readonly showCreateModal: boolean;

	/* Data
	============================================*/
	activeUserRoleId: number = null;

	/* Computed
	============================================*/

	@Sync('admin/activeUser')
	activeUser: IEaasiUser;

	@Get('admin/usersQuery')
	query: IEaasiSearchQuery;

	@Sync('admin/usersResult')
	list: IEaasiSearchResponse<User>;

	@Get('permissions')
	permit: PermissionResolver;

	get isViewable(): boolean {
		return this.permit.allowsManageOrganizationUsers();
	};

	/* Methods
	============================================*/

	getUsers() {
		this.$store.dispatch('admin/getUsers');
		this.$store.dispatch('admin/getRoles');
	}

	init() {
		this.getUsers();
	}

	paginate(page: number) {
		this.query.page = page;
		this.search();
	}

	search() {
		this.$store.dispatch('admin/getUsers');
	}

	setActiveUser(user: IEaasiUser) {
		this.activeUser = user ? user.copy() : null;
		if (user) {
			this.activeUserRoleId = jsonCopy(user.roleId);
		}
	}

	/* Lifecycle Hooks
	============================================*/

	mounted() {
		this.init();
	}

}
