














































import PermissionResolver from '@/services/Permissions/PermissionResolver';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import {IEaasiRole, IEaasiUser, IKeycloakUserInfo} from 'eaasi-admin';
import { IEaasiSearchQuery } from '@/types/Search';
import User from '@/models/admin/User';
import SortHeader from '@/components/global/tables/SortHeader.vue';
import ConfirmModal from '@/components/global/Modal/ConfirmModal.vue';
import {ROLES_MAPPER} from '@/utils/constants';

@Component({
	name: 'UserList',
	components: {
		SortHeader, ConfirmModal
	}
})
export default class UserList extends Vue {

	/* Props
	============================================*/

	@Prop({type: Array, required: true})
	readonly users: IKeycloakUserInfo[];

	/* Computed
	============================================*/
	get isViewable(): boolean {
		if (!this.currentUser) return false;
		return this.permit.allowsManageOrganizationUsers();
	};

	@Get('loggedInUser')
	currentUser: IEaasiUser;

	@Get('permissions')
	permit: PermissionResolver;

	@Get('admin/roles')
	roles: IEaasiRole[];

	@Sync('admin/usersQuery')
	query: IEaasiSearchQuery;

	/* Methods
	============================================*/

	editUser(u: IKeycloakUserInfo) {
		this.$emit('rowClick', new User(u));
	}

	getRole(user: IKeycloakUserInfo) {
		if (!user.attributes || !user.attributes.role || user.attributes.role.length === 0) return '';

		let role = this.roles.find(x => x.id === ROLES_MAPPER[user.attributes.role[0]]);
		return role ? role.roleName : '-';
	}

	sort(query: IEaasiSearchQuery) {
		this.query = query;
		this.$store.dispatch('admin/getUsers');
	}

}

